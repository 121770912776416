.footerParentDiv {
    margin-top: 200px;
    bottom: 0;
    width: 100%;
  }
  .footerParentDiv .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1565c0;
    color: #F2F2F2;
    padding: 30px;
  }


  .footerParentDiv .content .heading {
    font-size: 18px;
    font-weight: bolder;
    text-align: center;
  }
  a
  {
    text-decoration: none;
    color: #FFFF;

  }


   .footer {
    padding: 10px;
    background: #e48d41;
    color: #FFFF;
    text-align: center;
  }


  .contact
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homeLink
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }
